<template>
  <v-card>
      <div class="danger-alert" v-html="error" />
      <v-card-title>
        <span v-if="this.$store.state.language == 'EN'">
        Employee diary - list
        </span>
        <span v-if="this.$store.state.language == 'HR'">
        Radnici dnevnik - lista
        </span>
        
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="documentId"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
            :mobile-breakpoint="0" 
          >
          <template v-slot:[`item.OpenDate`]="{ item }">
              <span>{{ localDate(item.OpenDate) }}</span>
          </template>
          <template v-slot:[`item.ForDate`]="{ item }">
              <span>{{ localDate(item.ForDate) }}</span>
          </template>
          <template v-slot:[`item.AlarmDate`]="{ item }">
              <span>{{ localDate(item.AlarmDate) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
    </v-card>
</template>

<script>
import EmplDayService from '@/services/EmplDayService'
import dateFormat from '@/snippets/dateFormat'

export default {
  name: 'empldaylist',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      error: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0
      }
    }
  },
  async mounted () {
    try {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }

      const {data} = await EmplDayService.index(this.mainQuery)
      if (data && data.empldays) {
        this.items = data.empldays
      }
      // eslint-disable-next-line
      console.log('items', this.items)
      if (this.$store.state.language === 'EN') {
        this.headers.push(
          {
            text: 'Last Name',
            align: 'left',
            sortable: true,
            value: 'Last'
          },
          { text: 'First Name', sortable: true, value: 'First' },
          { text: 'Date open', sortable: true, value: 'OpenDate' },
          { text: 'Date change status', sortable: true, value: 'ForDate' },
          { text: 'Date end status', sortable: true, value: 'AlarmDate' },
          { text: 'Action type', sortable: true, value: 'ActionTypeName' },
          { text: 'Action', sortable: true, value: 'WorkingHoursTypeName' },
          { text: 'Hours', sortable: true, value: 'WorkingHours' },
          { text: 'Pension ID', sortable: true, value: 'PensionFundID' },
          { text: 'Health service', sortable: true, value: 'HealthServiceID' },
          { text: 'DbId', value: 'id' }
       )
      }
      if (this.$store.state.language === 'HR') {
        this.headers.push(
          {
            text: 'Prezime',
            align: 'left',
            sortable: true,
            value: 'Last'
          },
          { text: 'Ime', sortable: true, value: 'First' },
          { text: 'Datum upisa', sortable: true, value: 'OpenDate' },
          { text: 'Datum prijave/odjave/promjene', sortable: true, value: 'ForDate' },
          { text: 'Datum kraja statusa', sortable: true, value: 'AlarmDate' },
          { text: 'Vrsta akcije', sortable: true, value: 'ActionTypeName' },
          { text: 'Opis', sortable: true, value: 'WorkingHoursTypeName' },
          { text: 'Sati', sortable: true, value: 'WorkingHours' },
          { text: 'MIO', sortable: true, value: 'PensionFundID' },
          { text: 'HZZO', sortable: true, value: 'HealthServiceID' },
          { text: 'DbId', value: 'id' }
        )
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    navigateTo (params) {
      // eslint-disable-next-line
      console.log('Params ', params)
      this.$store.dispatch('setEmpldayid', params.id)
      this.$router.push({
        name: 'empldayview'
      })
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
