<template>
  <v-container>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

          <v-form
            name="DocumentForm"
            autocomplete="off">
            <v-row no-gutters>
                <h2>
                  <span v-if="this.$store.state.editItem  === 0">
                    {{langC.CreateNew}} - {{ documentNameLoc }}
                  </span>
                  <span v-if="this.$store.state.editItem === 1">
                    {{langC.Edit}} - {{ documentNameLoc }}
                  </span>                  
                </h2>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  {{lang.CompanyName}} <span style="font-weight:bold">{{mainbook.CompanyNameDatabase}}</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="py-0 px-2">
                  {{lang.BusinessYear}} <span style="font-weight:bold">{{mainbook.BusinessYear}}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2" v-if="show === 1">
                  <div class="green darken-2 text-center">
                    <span class="white--text display-1">{{lang.Rec}}</span>
                  </div>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2" v-if="show === 2">
                  <div class="blue darken-2 text-center">
                    <span class="white--text display-1">{{lang.Exp}}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-3">
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-dialog
                    ref="dialog1"
                    persistent
                    v-model="modal1"
                    width="290px"
                    :return-value.sync="mainbook.BookDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate1"
                      :label="lang.BookDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="mainbook.BookDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal1 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog1.save(mainbook.BookDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.BookID"
                      v-model="mainbook.BookID"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-btn text color="primary" class="pt-3" @click="addBook(show === 1 ? 1 : 2)">{{ show === 1 ? lang.AddInvoice : lang.AddRecivable}}</v-btn>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-btn text color="red darken-1" class="pt-3" @click="deleteBook(show === 1 ? 1 : 2)">{{ show === 1 ? lang.DeleteExpense : lang.DeleteReceive}}</v-btn>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-btn text color="primary" class="pt-3" @click="card()">{{ show === 1 ? lang.CardInvoice : lang.CardRecivable}}</v-btn>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-btn text color="primary" class="pt-3" @click="showBooks(show === 1 ? 1 : 2)">{{ show === 1 ? lang.BookOutInvoices : lang.BookInInvoices}}</v-btn>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="2" xl="2" class="py-0 px-2">
                  <v-checkbox
                    v-model="mainbook.useBuySupp"
                    class="ml-4 pt-0"
                  >
                  <template v-slot:label>
                        <span style="font-size: 12px">{{lang.useBuySupp}}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              
              <v-row no-gutters>
               <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                    <v-autocomplete
                      class="pr-3"
                      :items="types"
                      v-model="select"
                      label="Vrsta"
                      :search-input.sync="search"
                      item-text="BookTypeName"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-autocomplete>   
                </v-col>
                <v-col cols="12" sm="12" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.BookDesc1"
                      v-model="mainbook.BookDesc1"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="12" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.BookDesc2"
                      v-model="mainbook.BookDesc2"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                    <v-autocomplete
                      class="pr-3"
                      :items="docs"
                      v-model="selectDoc"
                      :label="lang.MainBookDocID"
                      item-text="DocID"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-autocomplete>   
                </v-col>
                <v-col cols="12" sm="12" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.MainBookDocName"
                      v-model="mainbook.BookDocDesc"
                      disabled
                    ></v-text-field> 
                </v-col>
                
              </v-row>

              <v-row no-gutters v-if="show === 1">
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.RecCash"
                      v-model.number="mainbook.RecCash"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.RecBank"
                      v-model.number="mainbook.RecBank"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.RecFree"
                      v-model.number="mainbook.RecFree"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.RecVAT"
                      v-model.number="mainbook.RecVAT"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.RecSpecial"
                      v-model.number="mainbook.RecSpecial"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <div class="text-left">
                      <span class="title green--text">Ukupno:{{formatPrice(mainbook.RecTotal)}}</span>
                    </div>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="show === 2">
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.ExpCash"
                      v-model.number="mainbook.ExpCash"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.ExpBank"
                      v-model.number="mainbook.ExpBank"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.ExpFree"
                      v-model.number="mainbook.ExpFree"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.ExpVAT"
                      v-model.number="mainbook.ExpVAT"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-2"
                      :label="lang.ExpSpecial"
                      v-model.number="mainbook.ExpSpecial"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <div class="text-left">
                      <span class="title indigo--text">Ukupno:{{formatPrice(mainbook.ExpTotal)}}</span>
                    </div>
                </v-col>
              </v-row>


            <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2">
                    {{ langC.Partner }}
                </v-col>

                <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="py-0 px-0 pr-2">
                  <v-autocomplete
                    :items="partners"
                    v-model="selectPa"
                    :label="langC.SelectPartner"
                    item-text="partnerName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field
                      label="OIB *"
                      v-model="mainbook.partnerVATID"
                      required
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <p class="text-right">
                     <v-btn text color="primary" class="pt-3" @click="addeditPartner()">{{lang.PartnerSave}}</v-btn>
                  </p>
                </v-col>

              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                    <v-text-field class="pr-2"
                      label="Naziv partnera"
                      v-model="mainbook.partnerName"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field class="pr-2"
                      label="IBAN"
                      v-model="mainbook.partnerBankIBAN"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field class="pr-2"
                    :label="lang.Partneremail"
                    v-model="mainbook.partneremail"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                    <v-text-field
                      label="Ulica"
                      v-model="mainbook.partnerStreet"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                    <v-text-field
                      label="Br"
                      v-model="mainbook.partnerBuildingNameNumber"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                    <v-text-field
                      label="Mjesto"
                      v-model="mainbook.partnerCity"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <v-text-field
                      label="Pošta"
                      v-model="mainbook.partnerPostcode"
                    ></v-text-field> 
                </v-col>
              </v-row>
              

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2 headline">
                    Broj računa:
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                  <v-text-field class="headline"
                      label="Broj računa"
                      v-model="mainbook.InvoiceNumber"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                  <v-text-field class="headline"
                      label="Broj otpremnice"
                      v-model="mainbook.DeliveryNoteNumber"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                  <v-dialog
                    ref="dialog2"
                    persistent
                    v-model="modal2"
                    width="290px"
                    :return-value.sync="mainbook.InvoiceDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="mainbook.InvoiceDate"
                      :label="lang.InvoiceDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="mainbook.InvoiceDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal2 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog2.save(mainbook.InvoiceDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                  <v-dialog
                      ref="dialog3"
                      persistent
                      v-model="modal3"
                      width="290px"
                      :return-value.sync="mainbook.InvoicePaymentDeadlineDate"
                    >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="mainbook.InvoicePaymentDeadlineDate"
                        :label="lang.InvoicePaymentDeadlineDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                      <v-date-picker v-model="mainbook.InvoicePaymentDeadlineDate" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modal3 = false">{{langC.Cancel}}</v-btn>
                        <v-btn color="primary" @click="$refs.dialog3.save(mainbook.InvoicePaymentDeadlineDate)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                </v-col>
              </v-row> 

             <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2 headline">
                    Iznos računa:
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-text-field
                      class="headline pr-4"
                      label="Primarni iznos"
                      v-model.number="mainbook.InvoiceAmount"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-2">
                    <v-select label="Primarna valuta" :items="currnecyType" v-model="mainbook.HomeCurrency" value="mainbook.HomeCurrency"></v-select>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pt-1 mr-1">
                  <v-text-field
                      dense
                      label="Tecaj:"
                      v-model.number="mainbook.ExRate"
                      type="number"
                  ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <v-select label="Sekundarna valuta" :items="currnecyType" v-model="mainbook.ForeignCurrency" value="mainbook.ForeignCurrency"></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pt-1 ml-3">
                  <v-text-field
                      dense
                      label="Sekun.iznos"
                      v-model.number="mainbook.InvoiceForeginAmount"
                      type="number"
                  ></v-text-field> 
                </v-col>
            </v-row>                           

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2" >
                PDV 25%:
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="25% PDV iznos"
                      v-model.number="mainbook.VAT1Amount"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
                <v-text-field
                      dense
                      label="%PDV može se odbiti"
                      v-model.number="mainbook.VAT1PerCanUse"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Izn. Može se odbiti"
                      v-model.number="mainbook.VAT1CanUse"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Izn.NE može se odbiti"
                      v-model.number="mainbook.VAT1CanTUse"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  <v-text-field
                      dense
                      label="Osnovica"
                      v-model.number="mainbook.VAT1Base"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                <v-btn color="blue darken-1" text @click="calc25">Izračunaj 25%</v-btn>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2" >
                PDV 13%:
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="13% PDV iznos"
                      v-model.number="mainbook.VAT2Amount"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label=" %PDV moze se odbiti"
                      v-model.number="mainbook.VAT2PerCanUse"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Izn. Može se odbiti"
                      v-model.number="mainbook.VAT2CanUse"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Izn.NE Može se odbiti"
                      v-model.number="mainbook.VAT2CanTUse"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="Osnovica"
                      v-model.number="mainbook.VAT2Base"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                <v-btn color="blue darken-1" text @click="calc13">Izračunaj 13%</v-btn>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2" >
                PDV 5%:
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="5% PDV iznos"
                      v-model.number="mainbook.VAT3Amount"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="%PDV moze se odbiti"
                      v-model.number="mainbook.VAT3PerCanUse"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Izn. Može se odbiti"
                      v-model.number="mainbook.VAT3CanUse"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Izn.NE moze se odbiti"
                      v-model.number="mainbook.VAT3CanTUse"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="Osnovica"
                      v-model.number="mainbook.VAT3Base"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                <v-btn color="blue darken-1" text @click="calc5">Izračunaj 5%</v-btn>
              </v-col>
            </v-row>             

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2" >
                Ostalo:
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Neoporezivi iznos"
                      v-model.number="mainbook.VAT4Base"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="PDV ukupno (25+13+5)"
                      v-model.number="mainbook.AmountVAT"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-3">
 
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="= Porezna osnovica:"
                      v-model.number="mainbook.AmountNetto"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
            </v-row> 

            <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2">
                  Ulaz na skladište:
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Broj skladista"
                      v-model.number="mainbook.WareHouseId"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Broj primke"
                      v-model.number="mainbook.WareHouseEntry"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Iznos primke"
                      v-model.number="mainbook.WareHouseEntryAmount"
                      type="number"
                  ></v-text-field>
                </v-col>
              </v-row>

          </v-form>
          <div class="pink" v-html="error" />

          <v-btn v-if="show === 1"
            class="mr-2"
            color="green"
            :disabled="!formIsValid"
            @click="register" >
            PROKNJIŽI {{lang.Rec}}
          </v-btn>
          <v-btn v-if="show === 2"
            class="mr-2"
            color="blue"
            :disabled="!formIsValid"
            @click="register" >
            PROKNJIŽI {{lang.Exp }}
          </v-btn>

          <v-btn
            color="purple"
            @click="mainledgerlist">
            {{langC.Back}}
          </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.haveBookIn || this.haveBookOut">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainLedgerListFast :partnerId="mainbook.partnerId" :docSide="docSide" />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="showCard">
      <MainBookCard2 :startDate="startDate" :endDate="endDate" :partnerId="partnerId" :MLType="MLType" />
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import MainBookService from '@/services/MainBookService'
import MainBookTypeService from '@/services/MainBookTypeService'
import MainBookDocService from '@/services/MainBookDocService'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerListFast from '../MainLedger/MainLedgerListFast'
import ledgerEN from './mainbookDescEn'
import ledgerHR from './mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { defMainBook, defNewMainBook, defNewpartner } from '../MainLedger/mainledgerHelper'
import {parseNum, round, dateFormat} from '@/snippets/allSnippets'
import MainBookCard2 from './MainBookCard2'
//import {checkPartners} from '../MainLedger/mainledgerHelper'
import { testLastUpdate, newUpdates } from '@/services/TestLastUpdate'
import dayjs from 'dayjs'

export default {
  name: 'mainbookcreate',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      search: null,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      cal25: false,
      cal13: false,
      cal5: false,
      msg: '',
      documentName: '',
      documentNameLoc: '',
      mainbook: { },
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      types: [],
      docs: [],
      CompanyId: 0,
      selectPa: {},
      select: {},
      selectDoc: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      localUser: '',
      bookType: [],
      bookTypeSelected: {
      },
      showUpload: false,
      haveItem: null,
      selectId: null,
      haveNew: false,
      show: 1,
      haveBookIn: false,
      haveBookOut: false,
      docSide: 1,
      showCard: false,
      startDate: null,
      endDate: null,
      partnerId: null,
      MLType: null
    }
  },
  components: {
    MainLedgerListFast,
    MainBookCard2
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.companyid) {
        this.CompanyId = this.$store.state.companyid
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      this.show = 1
      if (this.$store.state.tasktypeid === 1) {
        this.show = 1
      }
      if (this.$store.state.tasktypeid === 2) {
        this.show = 2
      }
      this.startDate = dayjs([this.$store.state.businessYear ? this.$store.state.businessYear : 2020]).startOf('year').format('YYYY-MM-DD')
      this.endDate = dayjs([this.$store.state.businessYear ? this.$store.state.businessYear : 2020]).endOf('year').format('YYYY-MM-DD')
      if (this.CompanyId > 0) {
        await testLastUpdate()
        this.partners = this.$store.state.partnerStore ? this.$store.state.partnerStore : []
        const haveTypes = (await MainBookTypeService.index(this.mainQuery)).data.mainbooktypes
        //this.types = haveTypes ? haveTypes.filter(type => (type.BookRecExp === this.show))  : []
        // for recivables and expandables show all types
        this.types = haveTypes ? haveTypes : []

        const haveDocs = (await MainBookDocService.index(this.mainQuery)).data.mainbookdocs
        //this.types = haveTypes ? haveTypes.filter(type => (type.BookRecExp === this.show))  : []
        // for recivables and expandables show all types
        this.docs = haveDocs ? haveDocs : []
      }
      
      if (this.$store.state.editItem === 0) {
        this.mainbook = defMainBook()
        this.mainbook = {...this.mainbook, ...defNewMainBook()}
        this.mainbook.BookRecExp  = this.show
        this.localUser = this.mainbook.First + ' ' + this.mainbook.Last + ', ' + this.mainbook.email
        this.haveNew = true
      }
      if (this.$store.state.editItem === 1) {
        const locDocument = await MainBookService.show(this.$store.state.mainledgerid)
        this.mainbook = locDocument.data.mainbook ? locDocument.data.mainbook : {}
        this.show = this.mainbook.BookRecExp === 1 ? 1 : 2

        this.mainbook.BookDate = this.mainbook.BookDate ?  dayjs(this.mainbook.BookDate).format('YYYY-MM-DD') : null
        if (this.mainbook.partnerId && this.mainbook.partnerId !== 0 ) {
          this.selectPa = this.partners.find(partner => (partner.id === parseInt(this.mainbook.partnerId)))
        }
        const haveTypes2 = (await MainBookTypeService.index(this.mainQuery)).data.mainbooktypes
        //this.types = haveTypes2 ? haveTypes2.filter(type => (type.BookRecExp === this.show))  : []
         // for recivables and expandables show all types
        this.types = haveTypes2 ? haveTypes2 : []
        if (this.mainbook.BookTypeId && this.mainbook.BookTypeId !== 0 ) {
          this.types.map(type => {
            if (type.id === this.mainbook.BookTypeId) {
              this.select = type
            }
          })

          //const tSelect = this.types && this.types.length > 0 ? this.types.filter(type => (type.id === parseInt(this.mainbook.BookTypeId))) : {}
          //this.select = tSelect && tSelect.length> 0 ? tSelect[0] : {}
        }

        if (this.mainbook.BookDocId && this.mainbook.BookDocId !== 0 ) {
          this.selectDoc = this.docs.find (doc => doc.id === this.mainbook.BookDocId)
        }
        this.haveNew = false        
        const locTypes = (await MainBookTypeService.index(this.mainQuery))
        const haveTypes = locTypes && locTypes.data && locTypes.data.mainbooktypes ? locTypes.data.mainbooktypes : []
        //this.types = haveTypes ? haveTypes.filter(type => (type.BookRecExp === this.show))  : []
        this.types = haveTypes ? haveTypes : []
        this.doParse()
      }
      if (this.$store.state.booktype) {
        this.bookType = this.$store.state.booktype.filter(book => {
              return book.side === this.$store.state.documentSide
            })
        this.bookTypeSelected = this.mainbook.BookTypeID ? this.bookType.find(book => (parseInt(book.value) === this.mainbook.BookTypeID )) : null
      }
    } catch (err) {
      console.log(err)
    }
    
  },
  computed: {
    formIsValid () {
      return this.mainbook.BookDate
    },
    formatDate1() {
      return this.mainbook.BookDate
        ? dateFormat(this.mainbook.BookDate)
        : "";
    },
  },
  methods: {
     formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async bookin() {
      this.docSide = 1
      await this.$store.dispatch('setDocumentSide', 1)
      this.haveBookIn = !this.haveBookIn        
    },
    async bookout() {
      this.docSide = 2
      await this.$store.dispatch('setDocumentSide', 2)
      this.haveBookOut = !this.haveBookOut        
    },
    showBooks() {
      if (this.selectPa.id) {
        if (this.show === 1) {
          this.bookout()
        }
        if (this.show === 2) {
          this.bookin()
        }
      } else {
        this.text = 'Greška!! Nije odabran niti jedan partner !'
        this.color = 'red'
        this.snackbar = true
      }

    },
    card() {
      if (this.selectPa.id) {
        if (this.show === 1) {
          this.bookincard()
        }
        if (this.show === 2) {
          this.bookoutcard()
        }
      } else {
        this.text = 'Greška!! Nije odabran niti jedan partner !'
        this.color = 'red'
        this.snackbar = true
      }
    },
    async bookincard() {
      this.partnerId = this.selectPa.id ? this.selectPa.id : 0
      this.docSide = 1
      await this.$store.dispatch('setDocumentSide', 1)
      this.MLType = 2
      this.showCard = !this.showCard        
    },
    async bookoutcard() {
      this.partnerId = this.selectPa.id ? this.selectPa.id : 0
      this.docSide = 2
      await this.$store.dispatch('setDocumentSide', 2)
      this.MLType = 1
      this.showCard = !this.showCard
    },
    async addBook() {
      if (this.mainbook.BookID) {
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.MLType = this.show === 1 ? 2 : 1
        this.mainQuery.BookID = this.mainbook.BookID

        const arrDocs = (await MainLedgerService.books(this.mainQuery)).data.documents
        const lP = arrDocs[0] ? arrDocs[0] : null
        if (lP) {
          this.selectPa = this.partners.find(partner => (partner.id === parseInt(lP.partnerId)))
          this.mainbook.partnerId = lP.partnerId ? lP.partnerId : null
          this.mainbook.partnerName = lP.partnerName ? lP.partnerName : ''
          this.mainbook.partnerNameShort = lP.partnerNameShort ? lP.partnerNameShort : ''
          this.mainbook.partnerNameDatabase = lP.partnerNameDatabase ? lP.partnerNameDatabase : ''
          this.mainbook.partnerBuildingNameNumber = lP.partnerBuildingNameNumber ? lP.partnerBuildingNameNumber : ''
          this.mainbook.partnerStreet = lP.partnerStreet ? lP.partnerStreet : ''
          this.mainbook.partnerCity = lP.partnerCity ? lP.partnerCity : ''
          this.mainbook.partnerPostcode = lP.partnerPostcode ? lP.partnerPostcode : null
          this.mainbook.partnerCountyRegion = lP.partnerCountyRegion ? lP.partnerCountyRegion : null
          this.mainbook.partnerState = lP.partnerState ? lP.partnerState : null
          this.mainbook.partnerVATID = lP.partnerVATID ? lP.partnerVATID : null
          this.mainbook.partneremail = lP.partneremail ? lP.partneremail : null
          this.mainbook.partnerPhones = lP.partnerPhones ? lP.partnerPhones : null
          this.mainbook.partnerBankName = lP.partnerBankName ? lP.partnerBankName : null
          this.mainbook.partnerBankAddress = lP.partnerBankAddress ? lP.partnerBankAddress : null
          this.mainbook.partnerBankIBAN = lP.partnerBankIBAN ? lP.partnerBankIBAN : null
          this.mainbook.partnerBankSWIFT = lP.partnerBankSWIFT ? lP.partnerBankSWIFT : null
          if (this.show === 1) {
            if (round(this.mainbook.RecBank, 2) === 0) {
              this.mainbook.RecBank = lP.InvoiceAmount ? parseNum(lP.InvoiceAmount) : 0
            }
            this.mainbook.BookDesc1 = 'IRA ' + lP.BookID + ', ' + this.formatDate(lP.BookingDate) + ', ' + lP.partnerName
            this.mainbook.InvoiceNumber = lP.BookID ? this.round(lP.BookID, 0) : 0
          }
          if (this.show === 2) {
            if (round(this.mainbook.ExpBank, 2) === 0) {
              this.mainbook.ExpBank = lP.InvoiceAmount ? parseNum(lP.InvoiceAmount) : 0
            }
            this.mainbook.BookDesc1 = 'URA ' + lP.BookID + ', ' + this.formatDate(lP.BookingDate) + ', ' + lP.partnerName
            this.mainbook.InvoiceNumber = lP.InvoiceNumber ? lP.InvoiceNumber : ""
          }       
          this.mainbook.InvoiceDate = lP.InvoiceDate ? lP.InvoiceDate : 0
          this.mainbook.InvoicePaymentDeadlineDate = lP.InvoicePaymentDeadlineDate ? lP.InvoicePaymentDeadlineDate : 0
          this.mainbook.InvoiceAmount = lP.InvoiceAmount ? round(parseNum(lP.InvoiceAmount), 2) : 0
          this.mainbook.InvoiceForeginAmount = lP.InvoiceForeginAmount ? parseNum(lP.InvoiceForeginAmount) : 0
          this.mainbook.VAT1Per = lP.VAT1Per ? parseNum(lP.VAT1Per) : 0
          this.mainbook.VAT1Base = lP.VAT1Base ? parseNum(lP.VAT1Base) : 0
          this.mainbook.VAT1Amount = lP.VAT1Amount ? parseNum(lP.VAT1Amount) : 0
          this.mainbook.VAT1PerCanUse = lP.VAT1PerCanUse ? parseNum(lP.VAT1PerCanUse) : 0
          this.mainbook.VAT1CanUse = lP.VAT1CanUse ? parseNum(lP.VAT1CanUse) : 0
          this.mainbook.VAT1CanTUse = lP.VAT1CanTUse ? parseNum(lP.VAT1CanTUse) : 0
          this.mainbook.VAT2Per = lP.VAT2Per ? parseNum(lP.VAT2Per) : 0
          this.mainbook.VAT2Base = lP.VAT2Base ? parseNum(lP.VAT2Base) : 0
          this.mainbook.VAT2Amount = lP.VAT2Amount ? parseNum(lP.VAT2Amount) : 0
          this.mainbook.VAT2PerCanUse = lP.VAT2PerCanUse ? parseNum(lP.VAT2PerCanUse) : 0
          this.mainbook.VAT2CanUse = lP.VAT2CanUse ? parseNum(lP.VAT2CanUse) : 0
          this.mainbook.VAT2CanTUse = lP.VAT2CanTUse ? parseNum(lP.VAT2CanTUse) : 0
          this.mainbook.VAT3Per = lP.VAT3Per ? parseNum(lP.VAT3Per) : 0
          this.mainbook.VAT3Base = lP.VAT3Base ? parseNum(lP.VAT3Base) : 0
          this.mainbook.VAT3Amount = lP.VAT3Amount ? parseNum(lP.VAT3Amount) : 0
          this.mainbook.VAT3PerCanUse = lP.partnerBankSWIFT ? parseNum(lP.partnerBankSWIFT) : 0
          this.mainbook.VAT3CanUse = lP.VAT3CanUse ? parseNum(lP.VAT3CanUse) : 0
          this.mainbook.VAT3CanTUse = lP.VAT3CanTUse ? parseNum(lP.VAT3CanTUse) : 0
          this.mainbook.VAT4Per = lP.VAT4Per ? parseNum(lP.VAT4Per) : 0
          this.mainbook.VAT4Base = lP.VAT4Base ? parseNum(lP.VAT4Base) : 0
          this.mainbook.VAT4Amount = lP.VAT4Amount ? parseNum(lP.VAT4Amount) : 0
          this.mainbook.VAT4PerCanUse = lP.VAT4PerCanUse ? parseNum(lP.VAT4PerCanUse) : 0
          this.mainbook.VAT4CanUse = lP.VAT4CanUse ? parseNum(lP.VAT4CanUse) : 0
          this.mainbook.VAT4CanTUse = lP.VAT4CanTUse ? parseNum(lP.VAT4CanTUse) : 0
          this.mainbook.VAT5Per = lP.VAT5Per ? parseNum(lP.VAT5Per) : 0
          this.mainbook.VAT5Base = lP.VAT5Base ? parseNum(lP.VAT5Base) : 0
          this.mainbook.VAT5Amount = lP.VAT5Amount ? parseNum(lP.VAT5Amount) : 0
          this.mainbook.VAT5PerCanUse = lP.VAT5PerCanUse ? parseNum(lP.VAT5PerCanUse) : 0
          this.mainbook.VAT5CanUse = lP.VAT5CanUse ? parseNum(lP.VAT5CanUse) : 0
          this.mainbook.VAT5CanTUse = lP.VAT5CanTUse ? parseNum(lP.VAT5CanTUse) : 0
          this.mainbook.AmountNetto = lP.AmountNetto ? parseNum(lP.AmountNetto) : 0
          this.mainbook.AmountVAT = lP.AmountVAT ? parseNum(lP.AmountVAT) : 0
          this.mainbook.AmountBrutto = lP.AmountBrutto ? parseNum(lP.AmountBrutto) : 0
          this.doRound()
        } else {
          this.text = 'Greška!! Dokument s tim broj NE postoji !'
          this.color = 'red'
          this.snackbar = true
        }
      }
    },
    async deleteBook() {
      try {
        if (this.mainbook.id) {
          await MainBookService.delete(this.mainbook.id)
          this.$router.push({
              name: 'mainbook'
            })
        }
      } catch(err) {
          this.text = 'Greška!! Dokument s tim broj NE postoji !' + err
          this.color = 'red'
          this.snackbar = true
      }
    },
    async register () {
      try {
         this.localError = false
         if (this.selectPa.partnerVATID) {
          const lP = (await PartnerService.show(this.selectPa.partnerVATID)).data.partner
          this.mainbook.partnerId = lP.id
          this.mainbook.partnerName = lP.partnerName ? lP.partnerName : ''
          this.mainbook.partnerNameShort = lP.partnerNameShort ? lP.partnerNameShort : ''
          this.mainbook.partnerNameDatabase = lP.partnerNameDatabase ? lP.partnerNameDatabase : ''
          this.mainbook.partnerBuildingNameNumber = lP.partnerBuildingNameNumber ? lP.partnerBuildingNameNumber : ''
          this.mainbook.partnerStreet = lP.partnerStreet ? lP.partnerStreet : ''
          this.mainbook.partnerCity = lP.partnerCity ? lP.partnerCity : ''
          this.mainbook.partnerPostcode = lP.partnerPostcode ? lP.partnerPostcode : null
          this.mainbook.partnerCountyRegion = lP.partnerCountyRegion ? lP.partnerCountyRegion : null
          this.mainbook.partnerState = lP.partnerState ? lP.partnerState : null
          this.mainbook.partnerVATID = lP.partnerVATID ? lP.partnerVATID : null
          this.mainbook.partneremail = lP.partneremail ? lP.partneremail : null
          this.mainbook.partnerPhones = lP.partnerPhones ? lP.partnerPhones : null
          this.mainbook.partnerBankName = lP.partnerBankName ? lP.partnerBankName : null
          this.mainbook.partnerBankAddress = lP.partnerBankAddress ? lP.partnerBankAddress : null
          this.mainbook.partnerBankIBAN = lP.partnerBankIBAN ? lP.partnerBankIBAN : null
          this.mainbook.partnerBankSWIFT = lP.partnerBankSWIFT ? lP.partnerBankSWIFT : null
        }
        this.doRound()
        this.mainbook.BookDate = dayjs(this.mainbook.BookDate).format('YYYY-MM-DD')
        if (this.$store.state.editItem === 0) {   
          await MainBookService.post(this.mainbook)
          .then(res => {
            this.mainbook = res.data.mainbook ? res.data.mainbook : {}
            if (this.$store.state.language === 'EN') {
              this.text = 'New mainbook doc has been saved.'
              this.color = 'green'
              this.snackbar = true
            }
            if (this.$store.state.language === 'HR') {
              this.text = 'Nova ura/ira je snimljena u bazu.'
              this.color = 'green'
              this.snackbar = true
            }
            this.timeout = 1000
            this.$router.push({
              name: 'mainbook'
            })
          })
          .catch(err => {
            this.timeout = 2000
            this.error = err.data
            this.text = 'Error1! Cannot save document to database. ' + err
            this.color = 'red'
            this.snackbar = true
          })
        }        
        if (this.$store.state.editItem === 1) {
          await MainBookService.put(this.mainbook)
          this.timeout = 1000
          this.$router.push({
            name: 'mainbook'
          })
        }

      } catch (error) {
        this.error = error.data
        this.text = 'Error2! Cannot save document to database. ' + error.data
        this.color = 'red'
        this.snackbar = true
      }
    },
    close () {
      this.show = false
    },
    calculateSecAmount () {
      this.mainbook.InvoiceForeginAmount = this.mainbook.ExRate !== 0 ? (this.mainbook.InvoiceAmount) / (this.mainbook.ExRate) : 0
      // this.mainbook.VAT1Base = this.round(((parseFloat(this.mainbook.InvoiceAmount) / (1 + parseFloat(this.mainbook.VAT1Per) / 100))),2)
      // this.mainbook.VAT1Amount = this.round((parseFloat(this.mainbook.InvoiceAmount) - this.mainbook.VAT1Base),2)
      // this.mainbook.VAT1CanUse = this.round((parseFloat(this.mainbook.InvoiceAmount) - this.mainbook.VAT1Base),2)
    },
    calculateFirstAmount () {
      if ((this.mainbook.InvoiceAmount) === 0) {
        this.mainbook.InvoiceAmount = this.mainbook.ExRate !== 0 ? (this.mainbook.InvoiceForeginAmount * this.mainbook.ExRate) : 0
        // this.mainbook.VAT1Base = this.round(((parseFloat(this.mainbook.InvoiceAmount) / (1 + parseFloat(this.mainbook.VAT1Per) / 100))),2)
        // this.mainbook.VAT1Amount = this.round((parseFloat(this.mainbook.InvoiceAmount) - this.mainbook.VAT1Base),2)
        // this.mainbook.VAT1CanUse = this.round((parseFloat(this.mainbook.InvoiceAmount) - this.mainbook.VAT1Base),2)
      }
    },
    calculateFirstVAT () {
      this.mainbook.VAT1CanUse = (((this.mainbook.VAT1Amount) * ((this.mainbook.VAT1PerCanUse) / 100)))
      this.mainbook.VAT1CanTUse = (this.mainbook.VAT1Amount) - this.mainbook.VAT1CanUse
      this.mainbook.VAT1Base = this.mainbook.VAT1Per !== 0 ? (this.mainbook.VAT1CanUse + this.mainbook.VAT1CanTUse) / ( this.mainbook.VAT1Per / 100) : 0
      this.calculateVATAmount()
    },
    calculateSecondVAT () {
      this.mainbook.VAT2CanUse = ((this.mainbook.VAT2Amount) * ((this.mainbook.VAT2PerCanUse) / 100))
      this.mainbook.VAT2CanTUse = (this.mainbook.VAT2Amount) - this.mainbook.VAT2CanUse
      this.mainbook.VAT2Base = this.mainbook.VAT2Per !== 0 ? (this.mainbook.VAT2CanUse + this.mainbook.VAT2CanTUse) / ( this.mainbook.VAT2Per / 100) : 0
      this.calculateVATAmount()
    },
    calculateThirdVAT () {
      this.mainbook.VAT3CanUse = ((this.mainbook.VAT3Amount) * ((this.mainbook.VAT3PerCanUse) / 100))
      this.mainbook.VAT3CanTUse = (this.mainbook.VAT3Amount) - this.mainbook.VAT3CanUse
      this.mainbook.VAT3Base = this.mainbook.VAT3Per !==0 ? (this.mainbook.VAT3CanUse + this.mainbook.VAT3CanTUse) / ( this.mainbook.VAT3Per / 100) : 0
      this.calculateVATAmount()
    },
    calculateVATAmount () {
      this.mainbook.AmountVAT = ((this.mainbook.VAT1Amount) + (this.mainbook.VAT2Amount) + (this.mainbook.VAT3Amount))
      this.mainbook.AmountNetto = ((this.mainbook.InvoiceAmount) - this.mainbook.AmountVAT - (this.mainbook.VAT4Base))
    },
    calculateRecExp() {
      if (!this.mainbook.BookDocId) {
        this.mainbook.RecTotal = (this.mainbook.RecCash ? this.mainbook.RecCash : 0) + (this.mainbook.RecBank ? this.mainbook.RecBank : 0) + (this.mainbook.RecFree ? this.mainbook.RecFree : 0)  - (this.mainbook.RecVAT ? this.mainbook.RecVAT : 0) - (this.mainbook.RecSpecial ? this.mainbook.RecSpecial : 0)
        this.mainbook.ExpTotal = (this.mainbook.ExpCash ? this.mainbook.ExpCash : 0) + (this.mainbook.ExpBank ? this.mainbook.ExpBank : 0) + (this.mainbook.ExpFree ? this.mainbook.ExpFree : 0) - (this.mainbook.ExpVAT ? this.mainbook.ExpVAT : 0) - (this.mainbook.ExpSpecial ? this.mainbook.ExpSpecial : 0)
      }
      if (this.mainbook.BookDocId) {
        this.mainbook.RecTotal = (this.mainbook.RecCash ? this.mainbook.RecCash : 0) + (this.mainbook.RecBank ? this.mainbook.RecBank : 0) + (this.mainbook.RecFree ? this.mainbook.RecFree : 0)  - (this.mainbook.RecVAT ? this.mainbook.RecVAT : 0) + (this.mainbook.RecSpecial ? this.mainbook.RecSpecial : 0)
        this.mainbook.ExpTotal = (this.mainbook.ExpCash ? this.mainbook.ExpCash : 0) + (this.mainbook.ExpBank ? this.mainbook.ExpBank : 0) + (this.mainbook.ExpFree ? this.mainbook.ExpFree : 0) - (this.mainbook.ExpVAT ? this.mainbook.ExpVAT : 0) + (this.mainbook.ExpSpecial ? this.mainbook.ExpSpecial : 0)
      }
    },
    async findPartner () {
      if (this.selectPa.partnerVATID) {
        const locPartner = (await PartnerService.show(this.selectPa.partnerVATID)).data.partner
        this.mainbook.partnerId = locPartner.id ? locPartner.id : null
        this.mainbook.partnerName = locPartner.partnerName
        this.mainbook.partnerNameShort = locPartner.partnerNameShort
        this.mainbook.partnerNameDatabase = locPartner.partnerNameDatabase
        this.mainbook.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
        this.mainbook.partnerStreet = locPartner.partnerStreet
        this.mainbook.partnerCity = locPartner.partnerCity
        this.mainbook.partnerPostcode = locPartner.partnerPostcode
        this.mainbook.partnerCountyRegion = locPartner.partnerCountyRegion
        this.mainbook.partnerState = locPartner.partnerState
        this.mainbook.partnerVATID = locPartner.partnerVATID
        this.mainbook.partneremail = locPartner.partneremail
        this.mainbook.partnerPhones = locPartner.partnerPhones
        this.mainbook.partnerBankName = locPartner.partnerBankName
        this.mainbook.partnerBankAddress = locPartner.partnerBankAddress
        this.mainbook.partnerBankIBAN = locPartner.partnerBankIBAN
        this.mainbook.partnerBankSWIFT = locPartner.partnerBankSWIFT
      }
    },
    doParse() {
      this.mainbook.BookID = this.mainbook.BookID ? parseInt(this.mainbook.BookID) : 0
      this.mainbook.InvoiceAmount = this.mainbook.InvoiceAmount ? parseNum(this.mainbook.InvoiceAmount) : 0
      this.mainbook.InvoiceForeginAmount = this.mainbook.InvoiceForeginAmount ? parseNum(this.mainbook.InvoiceForeginAmount) : 0
      this.mainbook.VAT1Per = this.mainbook.VAT1Per ? parseNum(this.mainbook.VAT1Per) : 25
      this.mainbook.VAT1Base = this.mainbook.VAT1Base  ? parseNum(this.mainbook.VAT1Base) : 0
      this.mainbook.VAT1Amount = this.mainbook.VAT1Amount  ? parseNum(this.mainbook.VAT1Amount) : 0
      this.mainbook.VAT1PerCanUse = this.mainbook.VAT1PerCanUse  ? parseNum(this.mainbook.VAT1PerCanUse) : 100
      this.mainbook.VAT1CanUse = this.mainbook.VAT1CanUse  ? parseNum(this.mainbook.VAT1CanUse) : 0
      this.mainbook.VAT1CanTUse = this.mainbook.VAT1CanTUse  ? parseNum(this.mainbook.VAT1CanTUse) : 0
      this.mainbook.VAT2Per = this.mainbook.VAT2Per  ? parseNum(this.mainbook.VAT2Per) : 13
      this.mainbook.VAT2Base = this.mainbook.VAT2Base  ? parseNum(this.mainbook.VAT2Base) : 0
      this.mainbook.VAT2Amount = this.mainbook.VAT2Amount  ? parseNum(this.mainbook.VAT2Amount) : 0
      this.mainbook.VAT2PerCanUse = this.mainbook.VAT2PerCanUse  ? parseNum(this.mainbook.VAT2PerCanUse) : 100
      this.mainbook.VAT2CanUse = this.mainbook.VAT2CanUse  ? parseNum(this.mainbook.VAT2CanUse) : 0
      this.mainbook.VAT2CanTUse = this.mainbook.VAT2CanTUse ? parseNum(this.mainbook.VAT2CanTUse) : 0
      this.mainbook.VAT3Per = this.mainbook.VAT3Per  ? parseNum(this.mainbook.VAT3Per) : 5
      this.mainbook.VAT3Base = this.mainbook.VAT3Base  ? parseNum(this.mainbook.VAT3Base) : 0
      this.mainbook.VAT3Amount = this.mainbook.VAT3Amount  ? parseNum(this.mainbook.VAT3Amount) : 0
      this.mainbook.VAT3PerCanUse = this.mainbook.VAT3PerCanUse ? parseNum(this.mainbook.VAT3PerCanUse) : 100
      this.mainbook.VAT3CanUse = this.mainbook.VAT3CanUse ? parseNum(this.mainbook.VAT3CanUse) : 0
      this.mainbook.VAT3CanTUse = this.mainbook.VAT3CanTUse ? parseNum(this.mainbook.VAT3CanTUse) : 0
      this.mainbook.VAT4Per = this.mainbook.VAT4Per ? parseNum(this.mainbook.VAT4Per) : 0
      this.mainbook.VAT4Base = this.mainbook.VAT4Base ? parseNum(this.mainbook.VAT4Base) : 0
      this.mainbook.VAT4Amount = this.mainbook.VAT4Amount ? parseNum(this.mainbook.VAT4Amount) : 0
      this.mainbook.VAT4PerCanUse = this.mainbook.VAT4PerCanUse ? parseNum(this.mainbook.VAT4PerCanUse) : 100
      this.mainbook.VAT4CanUse = this.mainbook.VAT4CanUse ? parseNum(this.mainbook.VAT4CanUse) : 0
      this.mainbook.VAT4CanTUse = this.mainbook.VAT4CanTUse ? parseNum(this.mainbook.VAT4CanTUse) : 0
      this.mainbook.VAT5Per = this.mainbook.VAT5Per ? parseNum(this.mainbook.VAT5Per) : 0
      this.mainbook.VAT5Base = this.mainbook.VAT5Base ? parseNum(this.mainbook.VAT5Base) : 0
      this.mainbook.VAT5Amount = this.mainbook.VAT5Amount ? parseNum(this.mainbook.VAT5Amount) : 0
      this.mainbook.VAT5PerCanUse = this.mainbook.VAT5PerCanUse ? parseNum(this.mainbook.VAT5PerCanUse) : 100
      this.mainbook.VAT5CanUse = this.mainbook.VAT5CanUse ? parseNum(this.mainbook.VAT5CanUse) : 0
      this.mainbook.VAT5CanTUse = this.mainbook.VAT5CanTUse ? parseNum(this.mainbook.VAT5CanTUse) : 0
      this.mainbook.RecCash = this.mainbook.RecCash ? parseNum(this.mainbook.RecCash) : 0
      this.mainbook.RecBank = this.mainbook.RecBank ? parseNum(this.mainbook.RecBank) : 0
      this.mainbook.RecFree = this.mainbook.RecFree ? parseNum(this.mainbook.RecFree) : 0
      this.mainbook.RecVAT = this.mainbook.RecVAT ? parseNum(this.mainbook.RecVAT) : 0
      this.mainbook.RecSpecial = this.mainbook.RecSpecial ? parseNum(this.mainbook.RecSpecial) : 0
      this.mainbook.RecTotal = this.mainbook.RecTotal ? parseNum(this.mainbook.RecTotal) : 0
      this.mainbook.ExpCash = this.mainbook.ExpCash ? parseNum(this.mainbook.ExpCash) : 0
      this.mainbook.ExpBank = this.mainbook.ExpBank ? parseNum(this.mainbook.ExpBank) : 0
      this.mainbook.ExpFree = this.mainbook.ExpFree ? parseNum(this.mainbook.ExpFree) : 0
      this.mainbook.ExpVAT = this.mainbook.ExpVAT ? parseNum(this.mainbook.ExpVAT): 0
      this.mainbook.ExpSpecial = this.mainbook.ExpSpecial ? parseNum(this.mainbook.ExpSpecial) : 0
      this.mainbook.ExpTotal = this.mainbook.ExpTotal ? parseNum(this.mainbook.ExpTotal) : 0
    },
    doRound() {
      this.mainbook.InvoiceAmount = this.mainbook.InvoiceAmount ? this.round(this.mainbook.InvoiceAmount, 2) : 0
      this.mainbook.InvoiceForeginAmount = this.mainbook.InvoiceForeginAmount ? this.round(this.mainbook.InvoiceForeginAmount, 2) : 0
      //this.mainbook.VAT1Per = this.mainbook.VAT1Per ? this.round(this.mainbook.VAT1Per) : 25
      this.mainbook.VAT1Base = this.mainbook.VAT1Base ? this.round(this.mainbook.VAT1Base, 2) : 0
      this.mainbook.VAT1Amount = this.mainbook.VAT1Amount ? this.round(this.mainbook.VAT1Amount, 2) : 0
      this.mainbook.VAT1PerCanUse = this.mainbook.VAT1PerCanUse ? this.round(this.mainbook.VAT1PerCanUse, 2) : 100
      this.mainbook.VAT1CanUse = this.mainbook.VAT1CanUse ? this.round(this.mainbook.VAT1CanUse, 2) : 0
      this.mainbook.VAT1CanTUse = this.mainbook.VAT1CanTUse ? this.round(this.mainbook.VAT1CanTUse, 2) : 0
      //this.mainbook.VAT2Per = this.mainbook.VAT2Per ? this.round(this.mainbook.VAT2Per) : 13
      this.mainbook.VAT2Base = this.mainbook.VAT2Base ? this.round(this.mainbook.VAT2Base, 2) : 0
      this.mainbook.VAT2Amount = this.mainbook.VAT2Amount ? this.round(this.mainbook.VAT2Amount, 2) : 0
      this.mainbook.VAT2PerCanUse = this.mainbook.VAT2PerCanUse ? this.round(this.mainbook.VAT2PerCanUse, 2) : 100
      this.mainbook.VAT2CanUse = this.mainbook.VAT2CanUse ? this.round(this.mainbook.VAT2CanUse, 2) : 0
      this.mainbook.VAT2CanTUse = this.mainbook.VAT2CanTUse ? this.round(this.mainbook.VAT2CanTUse, 2) : 0
      //this.mainbook.VAT3Per = this.mainbook.VAT3Per ? this.round(this.mainbook.VAT3Per) : 5
      this.mainbook.VAT3Base = this.mainbook.VAT3Base ? this.round(this.mainbook.VAT3Base, 2) : 0
      this.mainbook.VAT3Amount = this.mainbook.VAT3Amount ? this.round(this.mainbook.VAT3Amount, 2) : 0
      this.mainbook.VAT3PerCanUse = this.mainbook.VAT3PerCanUse ? this.round(this.mainbook.VAT3PerCanUse, 2) : 100
      this.mainbook.VAT3CanUse = this.mainbook.VAT3CanUse ? this.round(this.mainbook.VAT3CanUse, 2) : 0
      this.mainbook.VAT3CanTUse = this.mainbook.VAT3CanTUse ? this.round(this.mainbook.VAT3CanTUse, 2) : 0
      this.mainbook.VAT4Per = this.mainbook.VAT4Per ? this.round(this.mainbook.VAT4Per, 2) : 0
      this.mainbook.VAT4Base = this.mainbook.VAT4Base ? this.round(this.mainbook.VAT4Base, 2) : 0
      this.mainbook.VAT4Amount = this.mainbook.VAT4Amount ? this.round(this.mainbook.VAT4Amount, 2) : 0
      this.mainbook.VAT4PerCanUse = this.mainbook.VAT4PerCanUse ? this.round(this.mainbook.VAT4PerCanUse, 2) : 100
      this.mainbook.VAT4CanUse = this.mainbook.VAT4CanUse ? this.round(this.mainbook.VAT4CanUse, 2) : 0
      this.mainbook.VAT4CanTUse = this.mainbook.VAT4CanTUse ? this.round(this.mainbook.VAT4CanTUse, 2) : 0
      this.mainbook.VAT5Per = this.mainbook.VAT5Per ? this.round(this.mainbook.VAT5Per, 2) : 0
      this.mainbook.VAT5Base = this.mainbook.VAT5Base ? this.round(this.mainbook.VAT5Base, 2) : 0
      this.mainbook.VAT5Amount = this.mainbook.VAT5Amount ? this.round(this.mainbook.VAT5Amount, 2) : 0
      this.mainbook.VAT5PerCanUse = this.mainbook.VAT5PerCanUse ? this.round(this.mainbook.VAT5PerCanUse, 2) : 100
      this.mainbook.VAT5CanUse = this.mainbook.VAT5CanUse ? this.round(this.mainbook.VAT5CanUse, 2) : 0
      this.mainbook.VAT5CanTUse = this.mainbook.VAT5CanTUse ? this.round(this.mainbook.VAT5CanTUse, 2) : 0
      this.mainbook.RecCash = this.mainbook.RecCash ? this.round(this.mainbook.RecCash, 2) : 0
      this.mainbook.RecBank = this.mainbook.RecBank ? this.round(this.mainbook.RecBank, 2) : 0
      this.mainbook.RecFree = this.mainbook.RecFree ? this.round(this.mainbook.RecFree, 2) : 0
      this.mainbook.RecVAT = this.mainbook.RecVAT ? this.round(this.mainbook.RecVAT, 2) : 0
      this.mainbook.RecSpecial= this.mainbook.RecSpecial ? this.round(this.mainbook.RecSpecial, 2) : 0
      this.mainbook.RecTotal = this.mainbook.RecTotal ? this.round(this.mainbook.RecTotal, 2) : 0
      this.mainbook.ExpCash = this.mainbook.ExpCash ? this.round(this.mainbook.ExpCash, 2) : 0
      this.mainbook.ExpBank = this.mainbook.ExpBank ? this.round(this.mainbook.ExpBank, 2) : 0
      this.mainbook.ExpFree = this.mainbook.ExpFree ? this.round(this.mainbook.ExpFree, 2) : 0
      this.mainbook.ExpVAT = this.mainbook.ExpVAT ? this.round(this.mainbook.ExpVAT,2 ): 0
      this.mainbook.ExpSpecial = this.mainbook.ExpSpecial ? this.round(this.mainbook.ExpSpecial, 2) : 0
      this.mainbook.ExpTotal = this.mainbook.ExpTotal ? this.round(this.mainbook.ExpTotal, 2) : 0
    },
    async mainledgerlist () {
      try {
        await this.$router.push({
          name: 'mainbook'
        })
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    mainledgerupload() {
      this.showUpload = !this.showUpload
    },
    async mainledgeragain () {
      try {
        this.$store.dispatch('setpathAgain', 'mainledgercreate')
        await this.$router.push({
          name: 'mainbook'
        })
        // Object.assign(this.$data, this.$options.data.call(this))
        // location.reload()
        // this.$forceUpdate()
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    round(value, exp) {
      if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value)

        value = +value
        exp = +exp

      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split('e')
      value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)))

      // Shift back
      value = value.toString().split('e')
      return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp))
    },
    async addeditPartner () {
        const addeditPartner = {...defNewpartner()}
        addeditPartner.CompanyId = this.mainbook.CompanyId ? this.mainbook.CompanyId : ''
        addeditPartner.CompanyNameDatabase = this.mainbook.CompanyNameDatabase
        addeditPartner.BusinessYear = this.mainbook.BusinessYear
        addeditPartner.UserId = this.mainbook.UserId
        addeditPartner.email = this.mainbook.email
        addeditPartner.First = this.mainbook.First
        addeditPartner.Last = this.mainbook.Last
        addeditPartner.partnerName = this.mainbook.partnerName
        addeditPartner.partnerNameShort = this.mainbook.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerNameDatabase = this.mainbook.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerBuildingNameNumber = this.mainbook.partnerBuildingNameNumber
        addeditPartner.partnerStreet = this.mainbook.partnerStreet
        addeditPartner.partnerCity = this.mainbook.partnerCity
        addeditPartner.partnerPostcode = this.mainbook.partnerPostcode
        addeditPartner.partnerCountyRegion = this.mainbook.partnerCountyRegion
        addeditPartner.partnerState = this.mainbook.partnerState
        addeditPartner.partnerVATID = this.mainbook.partnerVATID
        addeditPartner.partneremail = this.mainbook.partneremail
        addeditPartner.partnerPhones = this.mainbook.partnerPhones
        addeditPartner.partnerBankName = this.mainbook.partnerBankName
        addeditPartner.partnerBankAddress = this.mainbook.partnerBankAddress
        addeditPartner.partnerBankIBAN = this.mainbook.partnerBankIBAN
        addeditPartner.partnerBankSWIFT = this.mainbook.partnerBankSWIFT
        addeditPartner.partnerContactTime = this.mainbook.partnerContactTime
        addeditPartner.partnerSecretaryTitle = this.mainbook.partnerSecretaryTitle
        addeditPartner.partnerSecretaryFirst = this.mainbook.partnerSecretaryFirst
        addeditPartner.partnerSecretaryLast = this.mainbook.partnerSecretaryLast
        addeditPartner.partnerSecretaryEmail = this.mainbook.partnerSecretaryEmail
        addeditPartner.partnerSecretaryPhone = this.mainbook.partnerSecretaryPhone
        addeditPartner.partnerSecretaryContactOption = this.mainbook.partnerSecretaryContactOption
        addeditPartner.privatePerson = this.mainbook.privatePerson
        this.saving = true
        if (this.mainbook.partnerId) {
            addeditPartner.id = this.mainbook.partnerId
            this.saving = true
            await PartnerService.put(addeditPartner)
            this.saving = false
            this.text = 'Partner updated!'
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            //await checkPartners(1)
            await newUpdates(2)
            await testLastUpdate()
              if (this.$store.state.partnerStore) {
                this.partners = this.$store.state.partnerStore
              }
        } else {
          if (addeditPartner.partnerName) {
            this.localError = null
            this.mainQuery2 = {}
            // if is private person then dont check
            if (addeditPartner.privatePerson === false) {
              // check if vatid exist and if exist then do not add partner

              if (!addeditPartner.partnerVATID) {
                this.localError =  this.localError + ', Error partner dot have VAT ID  '
              }
              if (addeditPartner.partnerVATID) {
                this.mainQuery2.partnerVATID = addeditPartner.partnerVATID
              }
              if (!this.localError) {
                await PartnerService.check(this.mainQuery2)
                  .then(res => {
                    if (res.data.partner) {
                      this.haveItem = res.data.partner
                      this.localError = this.lang.PartnerErrorVAT + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                    }
                    if (res.data.partner2) {
                      this.haveItem = res.data.partner2
                      this.localError = this.lang.PartnerErroremail + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                    }                
                  })
                  .catch(err => {
                    this.error = err.data
                    this.localError = false
                  })
              }
            }
            // can save as private person ONLY in document ServiceNotes and QuoteSales
            if (!this.localError) {
              this.saving = true
              const newPartner = await PartnerService.post(addeditPartner)
              this.saving = false
              this.text = this.lang.Partneradded
              this.color = 'green'
              this.snackbar = true
              this.timeout = 2000
              //await checkPartners(0)
              await newUpdates(2)
              await testLastUpdate()
              if (this.$store.state.partnerStore) {
                this.partners = this.$store.state.partnerStore
              }
              //this.partners = (await PartnerService.index()).data.partners
              this.mainbook.partnerId = newPartner.data.partner.id ? newPartner.data.partner.id : null
              this.selectId = this.mainbook.partnerId ? parseInt(this.mainbook.partnerId) : null
              this.selectPa = this.selectId ? this.partners.find(partner => (partner.id === this.selectId)) : null
            } else {
              this.text = this.localError
              this.color = 'red'
              this.snackbar = true
            }
            
          } else {
            // not enough data to save partner 
            this.text = 'Error! Partner name is empty. Can not save partner.'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          } 
        }
        this.saving = false
    },
    calc25 () {
      if (this.mainbook.InvoiceAmount) {
        this.mainbook.VAT1Amount = round((this.mainbook.InvoiceAmount - (this.mainbook.InvoiceAmount / 1.25)), 2)
        this.mainbook.VAT1PerCanUse = 100
        this.mainbook.VAT1CanUse = (((this.mainbook.VAT1Amount) * ((this.mainbook.VAT1PerCanUse) / 100)))
        this.mainbook.VAT1CanTUse = (this.mainbook.VAT1Amount) - this.mainbook.VAT1CanUse
        this.mainbook.VAT1Base = this.mainbook.VAT1Per !== 0 ? (this.mainbook.VAT1CanUse + this.mainbook.VAT1CanTUse) / ( this.mainbook.VAT1Per / 100) : 0
      }
    },
    calc13 () {
      if (this.mainbook.InvoiceAmount) {
        this.mainbook.VAT2Amount = round((this.mainbook.InvoiceAmount - (this.mainbook.InvoiceAmount / 1.13)), 2)
        this.mainbook.VAT2PerCanUse = 100
        this.mainbook.VAT2CanUse = (((this.mainbook.VAT2Amount) * ((this.mainbook.VAT2PerCanUse) / 100)))
        this.mainbook.VAT2CanTUse = (this.mainbook.VAT2Amount) - this.mainbook.VAT2CanUse
        this.mainbook.VAT2Base = this.mainbook.VAT2Per !== 0 ? (this.mainbook.VAT2CanUse + this.mainbook.VAT2CanTUse) / ( this.mainbook.VAT2Per / 100) : 0
      }
    },
    calc5 () {
      if (this.mainbook.InvoiceAmount) {
        this.mainbook.VAT3Amount = round((this.mainbook.InvoiceAmount - (this.mainbook.InvoiceAmount / 1.05)), 2)
        this.mainbook.VAT3PerCanUse = 100
        this.mainbook.VAT3CanUse = (((this.mainbook.VAT3Amount) * ((this.mainbook.VAT3PerCanUse) / 100)))
        this.mainbook.VAT3CanTUse = (this.mainbook.VAT3Amount) - this.mainbook.VAT3CanUse
        this.mainbook.VAT3Base = this.mainbook.VAT3Per !== 0 ? (this.mainbook.VAT3CanUse + this.mainbook.VAT3CanTUse) / ( this.mainbook.VAT3Per / 100) : 0
      }
    },
    calcVAT () {
      const claimVAT = (this.mainbook.VAT1CanUse !== 0 ? this.mainbook.VAT1CanUse : 0) + (this.mainbook.VAT2CanUse !== 0 ? this.mainbook.VAT2CanUse : 0) + (this.mainbook.VAT3CanUse !== 0 ? this.mainbook.VAT3CanUse : 0)
      if (this.show === 1) {
        this.mainbook.RecVAT = claimVAT
      }
      if (this.show === 2) {
        this.mainbook.ExpVAT = claimVAT
      }
    }
  },
  watch: {
    'mainbook.VAT1Amount' () {
      this.calculateFirstVAT()
    },
    'mainbook.VAT1PerCanUse' () {
      this.calculateFirstVAT()
    },
    'mainbook.VAT2Amount' () {
      this.calculateSecondVAT()
    },
    'mainbook.VAT2PerCanUse' () {
      this.calculateSecondVAT()
    },
    'mainbook.VAT3Amount' () {
      this.calculateThirdVAT()
    },
    'mainbook.VAT3PerCanUse' () {
      this.calculateThirdVAT()
    },
    'mainbook.InvoiceAmount' () {
      this.calculateSecAmount()
    },
    'mainbook.InvoiceForeginAmount' () {
      this.calculateFirstAmount()
    },
    'mainbook.VAT4Base' () {
      this.calculateVATAmount()
    },
    'selectPa' () {
      this.findPartner()
    },
    'mainbook.RecCash' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.RecBank' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.RecFree' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.RecVAT' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.RecSpecial' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpCash' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpBank' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpFree' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpVAT' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpSpecial' () {
      this.doParse()
      this.calculateRecExp()
    },
    'select' () {
      if (this.select) {
      this.mainbook.BookTypeId = this.select && this.select.id && typeof(this.select.id) === 'string' ? parseInt(this.select.id) : this.select.id
      this.mainbook.BookTypeName = this.select.BookTypeName}
    },
    'selectDoc' () {
      if (this.selectDoc) {
        this.mainbook.BookDocId = this.selectDoc && this.selectDoc.id ? parseInt(this.selectDoc.id) : this.selectDoc.id
        this.mainbook.BookDocDesc = this.selectDoc.DocName ? this.selectDoc.DocName : ''
        this.mainbook.BookDesc2 = this.selectDoc.DocID ? this.selectDoc.DocID : ''
      }
    },
    'mainbook.VAT1CanUse' () {
      this.calcVAT()      
    },
    'mainbook.VAT2CanUse' () {
      this.calcVAT()      
    },
    'mainbook.VAT3CanUse' () {
      this.calcVAT()      
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
