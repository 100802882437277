<template>
   <v-form
    ref="form"
    v-model="valid"
    lazy-validation>
    <v-container>
      <v-alert type="warning" v-model="havealert" dismissible close-text="Close Alert">
          {{havemssgalert}}
      </v-alert>
      <v-row align="center" justify="center" class="mt-5">
        <v-col cols="11" xs="10" sm="10" md="4" lg="2" xl="2" >

            <v-text-field
              label="Email"
              v-model="email"
              :rules="emailRules"
            ></v-text-field>
            <br>
            <v-text-field
              label="Password"
              type="password"
              v-model="password"
              :rules="[rules.required]"
            ></v-text-field>
            <br>
            <div>
              <span  style="color:red; font-weight:bold">{{error}}</span>
            </div>
            <br>
            <v-row align="center">
              <v-col class="text-center" cols="12" sm="12">
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="login">
                Login
              </v-btn>
              <v-progress-circular v-if="this.saving"
                indeterminate
                color="green"
              ></v-progress-circular>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col class="text-center" cols="12" sm="12">
              <v-btn text small color="primary"
                @click="register">
                Register new user
              </v-btn>

              <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                bottom
                :color="color"
                >
                {{ text }}
              </v-snackbar>

              </v-col>

            </v-row>
        </v-col>
      </v-row>
     <div v-if="showDialog">
      <JustWait :message="message" :showDialog="showDialog" />
    </div>
    </v-container>
  </v-form>
</template>

<script>
import UserService from '@/services/UserService'
import CompUserService from '@/services/CompUserService'
import CompService from '@/services/CompService'
import CompBusinessYearService from '@/services/CompBusinessYearService'
import Account4Service from '@/services/Account4Service'
// import Account3Service from '@/services/Account3Service'
// import Account2Service from '@/services/Account2Service'
// import Account1Service from '@/services/Account1Service'
import ItemService from '@/services/ItemService'
import GroupService from '@/services/GroupService'
import EmployeeService from '@/services/EmployeeService'
import MainLedgerProjectTypeService from '@/services/MainLedgerProjectTypeService'
import PartnerService from '@/services/PartnerService'
import WareHouseMarkService from '@/services/WareHouseMarkService'
import {testLastUpdate} from '@/services/TestLastUpdate'
import JustWait from '../snippets/JustWait'

export default {
  name: 'login',
  data () {
    return {
      saving: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      email: '',
      password: '',
      error: null,
      locError: 0,
      currentYear: 0,
      userHaveCompany: [],
      companyBY: [],
      defaultCompanyId: 0,
      mainQuery: {
        email: ''
      },
      mainQuery2: {
      },
      account1s: [],
      account2s: [],
      account3s: [],
      account4s: [],
      itemsLoc: [],
      groupsLoc: [],
      employes: [],
      havealert: false,
      havemssgalert: '',
      privateP: false,
      finance3: false,
      finance4: false,
      allowFin: false,
      message: '',
      showDialog: false
    }
  },
  mounted () {
    this.message = 'Please wait...'
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        // this.snackbar = true
        this.login()
      }
    },
    async login () {

      try {
        this.locError = 0
        const response = await UserService.login({
          email: this.email,
          password: this.password
        })


        if (response.data.user.Activ === false) {
          this.locError = 1
          this.text = 'Sorry user is not activ. Please contact administrator!'
          this.color = 'red'
          this.snackbar = true
          this.$router.push({
            path: '/'
          })
        }
        if (this.locError === 0) {
          this.showDialog = true
          this.$store.dispatch('setToken', response.data.token)
          this.$store.dispatch('setUser', response.data.user)
          this.mainQuery.email = this.email
          //  -------------------------  Default Company
          // If have user, then choose default company
          //  -------------------------  Default Company

          this.userHaveCompany = (await CompUserService.choose(this.mainQuery)).data.companyUser
          if (typeof this.userHaveCompany !== 'undefined' && this.userHaveCompany !== null && this.userHaveCompany.length > 0) {
            this.userHaveCompany.forEach(userComp => {
              if (userComp.defaultCompany) {
                this.defaultCompanyId = userComp.CompanyId
              }
            })
            // if user dont have default company then choose with first in array number
            if (this.defaultCompanyId === 0) {
              this.defaultCompanyId = this.userHaveCompany[0].CompanyId
            }
          } else {
            this.locError = 1
            this.text = 'User DONT have permision in any company!'
            this.color = 'red'
            this.snackbar = true

            this.$store.dispatch('setBusinessYear', null)
            this.$store.dispatch('setCompanyName', null)
            this.$store.dispatch('setCompanyid', null)
            this.$store.dispatch('setCompanyActiv', null)
            this.$store.dispatch('setToken', null)
            this.$store.dispatch('setUser', null)
            this.$store.dispatch('setProjectTypes', null)
            this.$store.dispatch('setDateItems', null)
            this.$store.dispatch('setDatePartners', null)
            this.$store.dispatch('setDateAccounts', null)
            this.$store.dispatch('setWareHouseMarkid', null)
            this.$store.dispatch('setWarehouseState', null)
            this.$router.push({
              path: '/'
            })
          }
        }
        //  -------------------------  BusinessYear
        // have a companyId then find all company data
        //  -------------------------  BusinessYear

        if (this.locError === 0) {
          this.mainQuery2.CompanyId = this.defaultCompanyId
          this.currentYear = new Date().getFullYear()
          this.companyBY = (await CompBusinessYearService.choose(this.mainQuery2)).data.companyBY
          if (this.companyBY) {
            this.companyBY.forEach(compBY => {
              if (compBY.defaultBusinessYear) {
                this.currentYear = compBY.BusinessYear
              }
            })
          } else {
            this.text = 'Business Year is NOT set for these company! Default current year!'
            this.color = 'red'
            this.snackbar = true
          }

          //  -------------------------  Company data object
          const locCompSelect = (await CompService.show(this.defaultCompanyId)).data.company
          //  -------------------------  Set all to Store
          this.$store.dispatch('setCompanyid', locCompSelect.id)
          this.$store.dispatch('setCompanyName', locCompSelect.CompanyNameDatabase)
          this.$store.dispatch('setCompanyActiv', locCompSelect)
          this.$store.dispatch('setBusinessYear', this.currentYear)
          this.$store.dispatch('setWareHouseMarkid', 1)
          // when have company, then use company default business year
          this.saving = true

          await ItemService.index(this.mainQuery2).then(res => {
            if (res.data.items) {
              this.$store.dispatch('setDateItems', new Date())
              this.$store.dispatch('setActivItems', res.data.items)
            }
          })
          .catch(err => {
            this.havealert = !this.havealert
            this.havemssgalert = 'Error on init Items ' + err
          })

          await GroupService.index(this.mainQuery2).then(res => {
            if (res.data.groups) {
              this.$store.dispatch('setActivGroups', res.data.groups)
            }
          })
          .catch(err => {
            this.havealert = !this.havealert
            this.havemssgalert = 'Error on init Groups ' + err
          })

          await EmployeeService.index(this.mainQuery2).then(res => {
            if (res.data.employes) {
              this.$store.dispatch('setActivEmployes', res.data.employes)
            }
          })
          .catch(err => {
            console.log('Error init Employees ', err)
          })

          await MainLedgerProjectTypeService.index2(this.mainQuery2).then(res => {
            if (res.data.mainledgerprojecttype) {
              this.$store.dispatch('setProjectTypes', res.data.mainledgerprojecttype)
            }
          })
          .catch(err => {
            console.log('Error init MainLedgerProjectTypes ', err)
          })

          await WareHouseMarkService.index(this.mainQuery2).then(res => {
            if (res.data.wareHouseMarks) {
              this.$store.dispatch('setWarehouseState', res.data.wareHouseMarks)
            }
          })
          .catch(err => {
            console.log('Error init setWarehouseState ', err)
          })

          this.mainQuery2.noPageing = 0
          const privateP = "privatePartner" in this.$store.state.user ? this.$store.state.user.privatePartner : true
          const {data} = privateP ? (await PartnerService.index2(this.mainQuery2)) : (await PartnerService.index(this.mainQuery2))

          if (data && data.partners && data.partners.rows) {
            this.$store.dispatch('setDatePartners', new Date())
            this.$store.dispatch("setPartners", data.partners.rows)
          }
          this.privateP = "privatePartner" in this.$store.state.user ? this.$store.state.user.privatePartner : true
          this.finance3 = "finance3" in this.$store.state.user ? this.$store.state.user.finance3 : true

          if (this.finance3) {
            this.allowFin = true
          }
          if (this.allowFin) {
             await Account4Service.index(this.mainQuery2).then(res => {
              if (res.data.account4s) {
                  this.$store.dispatch('setDateAccounts', new Date())
                  this.$store.dispatch('setAccount4s', res.data.account4s)
                }
              })
              .catch(err => {
                this.havealert = !this.havealert
                this.havemssgalert = 'Error on init account4s ' + err
              })
          }

          // this.itemsLoc = (await ItemService.index(this.mainQuery2)).data.items
          // if (this.itemsLoc) {
          //   this.$store.dispatch('setActivItems', this.itemsLoc)
          // }
          // this.groupsLoc = (await GroupService.index(this.mainQuery2)).data.groups
          // if (this.groupsLoc) {
          //   this.$store.dispatch('setActivGroups', this.groupsLoc)
          // }
          // this.employes = (await EmployeeService.choose(this.mainQuery2)).data.employes
          // if (this.employes) {
          //   this.$store.dispatch('setActivEmployes', this.employes)
          // }
          this.saving = false
          await testLastUpdate()
          this.showDialog = false
 
          if (this.$route.name !== 'home') {
            this.$router.push({
              name: 'home'
            })
          }
        }
      } catch (error) {
        this.error = 'ERROR!! ' + (error.response.data && error.response.data.message? error.response.data.message : error)
      }
    },
    register () {
      this.$router.push({
        name: 'userregister'
      })
    },
    async writeDatePartners() {
      await this.$store.dispatch('setDatePartners', new Date())
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    JustWait
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
