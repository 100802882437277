<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2>
          {{lang.EmployeeCreate}}
        </h2>
      </v-col>
    </v-row>
    <v-row>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <h3 class="text-xs-left">
              {{lang.BasicData}}  
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <v-text-field
              :label="lang.hashtag"
              required
              :rules="[required]"
              v-model="employee.hashtag"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-checkbox
              :label="lang.Activ"
              v-model="employee.Activ"
            ></v-checkbox>
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-checkbox
              :label="lang.Black"
              v-model="employee.Black"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.First"
              required
              :rules="[required]"
              v-model="employee.First"
            ></v-text-field> 
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.Last"
              required
              :rules="[required]"
              v-model="employee.Last"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
             <v-text-field
                label="email *:"
                required
                :rules="[required]"
                v-model="employee.eemail"
              ></v-text-field>
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <v-text-field
                :label="lang.phone"
                v-model="employee.phone"
              ></v-text-field>
          </v-col>
        </v-row> 

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
          <v-text-field
              :label="lang.VatID"
              required
              :rules="[required]"
              v-model="employee.VatID"
            ></v-text-field>            
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
             <v-text-field
              :label="lang.Gender"
              v-model="employee.Gender"
            ></v-text-field> 
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.PersonalID1"
              v-model="employee.PersonalID1"
            ></v-text-field> 
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.PersonalID2"
              v-model="employee.PersonalID2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            {{lang.DateOfBirht}} <input type="date"  v-model="employee.DateOfBirht" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
                :label="lang.PlaceOfBirht"
                v-model="employee.PlaceOfBirht"
              ></v-text-field> 
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.PlaceOfBirthState"
              v-model="employee.PlaceOfBirthState"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.Foriner"
              v-model="employee.Foriner"
            ></v-text-field> 
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.ForinerLicense"
              v-model="employee.ForinerLicense"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-btn
              dark
              class="blue mr-2"
              :disabled="!formIsValid"
              @click="register">
              {{langC.Save}}
            </v-btn>
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-btn
              color="green"
              @click="employeeList">
              {{langC.Back}}
            </v-btn>
          </v-col>
        </v-row>

      </v-col>


      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">
        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
             <h3 class="text-xs-left">
                {{lang.AdditionalData}}
              </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
             <v-textarea
                :label="lang.PlaceOfLive"
                v-model="employee.PlaceOfLive"
              ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
             <v-text-field
              :label="lang.Occupation"
              v-model="employee.Occupation"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.Degree"
              v-model="employee.Degree"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <v-text-field
              :label="lang.SpecialKnowledge"
              v-model="employee.SpecialKnowledge"
            ></v-text-field>
          </v-col>
        </v-row> 

        <v-row>
          <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
            {{lang.StartInCompany}} <input type="date"  v-model="employee.StartInCompany" />
          </v-col>

          <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
            <v-text-field
              :label="lang.WorkPlace"
              v-model="employee.WorkPlace"
            ></v-text-field>
          </v-col>
 
          <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
            <v-text-field
              :label="lang.WeeklyWorkHours"
              v-model="employee.WeeklyWorkHours"
            ></v-text-field>
          </v-col>

        </v-row>


        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <v-text-field
              label="Staž sa povećanim trajanjem Joppd 7.1 (oznaka 0 ili 1)"
              v-model="employee.Joppd71Oznaka"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row> 

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.WorkForOtherCompany"
              v-model="employee.WorkForOtherCompany"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.WorkForOtherCompanyAbroad"
              v-model="employee.WorkForOtherCompanyAbroad"
            ></v-text-field>
          </v-col>
        </v-row> 


        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.WorkForOtherConnectedCompany"
              v-model="employee.WorkForOtherConnectedCompany"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.WorkHardConditions"
              v-model="employee.WorkHardConditions"
            ></v-text-field>
          </v-col>
        </v-row> 

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.NotWorkingTime"
              v-model="employee.NotWorkingTime"
            ></v-text-field>
          </v-col>
        </v-row> 

          
      </v-col>


      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <h3 class="text-xs-left">
              {{lang.InternalNotes}}        
            </h3>
          </v-col>
        </v-row> 

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <v-textarea
              :label="lang.OtherData"
              v-model="employee.OtherData"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            {{lang.EndDateInCompany}} <input type="date"  v-model="employee.EndDateInCompany" />
          </v-col>
        </v-row> 

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <v-text-field
              :label="lang.EndReason"
              v-model="employee.EndReason"
            ></v-text-field>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import EmployeeService from '@/services/EmployeeService'
import langEn from './empDescEn'
import langHr from './empDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'employeecreate',
  data () {
    return {
      lang: {},
      langC: {},
      menu: false,
      menu2: false,
      menu3: false,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      msg: '',
      newEmployee: {},
      employee: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 1,
        hashtag: '',
        email: '',
        eemail: '',
        phone: '',
        First: '',
        Last: '',
        Gender: '',
        VatID: '',
        PersonalID1: '',
        PersonalID2: '',
        PersonalID3: '',
        PersonalID4: '',
        PersonalID5: '',
        DateOfBirht: null,
        PlaceOfBirht: '',
        PlaceOfBirthSate: '',
        Foriner: '',
        ForinerLicense: '',
        PlaceOfLive: '',
        Occupation: '',
        Degree: '',
        SpecialKnowledge: '',
        StartInCompany: null,
        WorkForOtherCompany: '',
        WorkForOtherCompanyAbroad: '',
        WorkForOtherConnectedCompany: '',
        WorkHardConditions: '',
        WorkPlace: '',
        WeeklyWorkHours: 40,
        NotWorkingTime: '',
        EndDateInCompany: null,
        EndReason: '',
        OtherData: '',
        WorkYearsBefore: 0,
        WorkMonthsBefore: 0,
        WorkDaysBefore: 0,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        Activ: false,
        Black: false,
        EmployeeID1: '',
        EmployeeID2: '',
        EmployeeID3: '',
        EmployeeID4: '',
        originalname: '',
        filename: '',
        destination: '',
        path: '',
        typeDoc: '',
        LastUpdateBy: null,
        LastUpdate: null
      },
      required: (value) => !!value || 'Required.',
      error: ''
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHr
      this.langC = commHr;
    }
    if (this.$store.state.editItem === 1) {
      if (!this.$store.state.employeeid) {
        this.employeeList()
        return
      }
      const dbEmployee = await EmployeeService.show(this.$store.state.employeeid)
      // console.log(' imam radnika: ', dbEmployee.data.employee)
      this.employee = dbEmployee.data.employee
    }
  },
  methods: {
    async register () {
      try {
        if (this.$store.state.user.id) {
          this.employee.UserId = this.$store.state.user.id
        }
        if (this.$store.state.user.email) {
          this.employee.email = this.$store.state.user.email
          this.employee.LastUpdateBy = this.$store.state.user.email
        }
        if (this.$store.state.companyid) {
          this.employee.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.companyName) {
          this.employee.CompanyNameDatabase = this.$store.state.companyName
        }
        if (this.$store.state.busniessYear) {
          this.employee.BusinessYear = this.$store.state.busniessYear
        }
        if (this.$store.state.editItem === 0) {
          await EmployeeService.post(this.employee)
          this.$router.push({
            name: 'employee'
          })
        }
        if (this.$store.state.editItem === 1) {
          await EmployeeService.put(this.employee)
          this.$router.push({
            name: 'employeeview'
          })
        }
        
      } catch (error) {
        this.error = error.response.data.error
      }
    },
    async employeeList () {
      try {
        await this.$router.push({
          name: 'employeeview'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    doFlap1 () {
      if (this.employee.Activ === true) {
        this.employee.Black = false
      }
    },
    doFlap2 () {
      if (this.employee.Black === true) {
        this.employee.Activ = false
      }
    }
  },
  computed: {
    formIsValid () {
      return this.employee.eemail !== '' &&
        this.employee.hashtag !== '' &&
        this.employee.First !== '' &&
        this.employee.Last !== '' &&
        this.employee.VatID !== ''
    }
  },
  watch: {
    menu (val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    'employee.Activ' () {
      this.doFlap1()
    },
    'employee.Black' () {
      this.doFlap2()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
