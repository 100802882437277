<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>
        <v-row no-gutters>
          <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="text-left">
          </v-col>

          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
          </v-col>
          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">

          </v-col>
          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
            <v-btn
              class="primary"
              @click="print">
              Print
            </v-btn>
          </v-col>
        </v-row>


        <div id="printArea">

              <v-row v-for="item in items" :key="item.id" no-gutters>
               <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
               <v-row  v-if="item.Head" no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <br />
                    </v-col>
                  </v-row>
                 <v-row  v-if="item.Head" no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <MainLedgerCardHead :head="item" :type="type"/>
                    </v-col>
                  </v-row>
                  <v-row  v-if="item.Head" no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <MainLedgerCard4Head :type="type"/>
                    </v-col>
                  </v-row>
                  <v-row  v-if="item.Footer" no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <MainLedgerCard4Footer :total1="item.Total1" :total2="item.Total2" :total5="item.Total5" :showEUR="showEUR"/>
                    </v-col>
                  </v-row>
                   <v-row  v-if="!item.Head && !item.Footer" no-gutters>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                        {{ item.Line }}. {{ item.BookName !== 'BS' ? item.BookName : 'Izv.' }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                       {{ parseInt(item.BookID) }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                        {{ localDate(item.BookingDate) }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size: 12px">
                        {{ item.InvoiceNumber }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                        {{ formatPrice(parseNum2(item.Debit) / exRate, 2) }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                        {{ formatPrice(parseNum2(item.Credit) / exRate, 2) }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                        {{ formatPrice(parseNum2(item.Total5) / exRate, 2) }}
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-center" style="font-size: 12px">
                        {{ item.Description ? item.Description.substr(0,25) : '' }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size: 12px" v-if="type == 4">
                        {{ item.partnerName ? item.partnerName.substr(0,25) : '' }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="type == 4">
                        {{ item.TypeName}}
                      </v-col>
                       <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="type == 6">
                        {{ localDate(item.InvoiceDate) ? localDate(item.InvoiceDate) : '' }}
                      </v-col>
                       <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="type == 6">
                        {{ localDate(item.InvoicePaymentDeadlineDate) ? localDate(item.InvoicePaymentDeadlineDate) : '' }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size: 12px">
                         {{ item.invoiceInNumber > 0 ? 'URA-' + item.invoiceInNumber : '' }}
                         {{ item.invoiceOutNumber > 0 ? 'IRA-' + item.invoiceOutNumber : '' }}
                      </v-col>
                  </v-row>

                </v-col>
            </v-row>
            
        </div>
      </v-flex>
       <MainLedgerPrintCard4 ref="prnt" :card="items" :type="type" :exRate="exRate"/>
    </v-layout>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import MainLedgerPrintCard4 from './MainLedgerPrintCard4'
import MainLedgerCardHead from './MainLedgerCardHead'
import MainLedgerCard4Head from './MainLedgerCard4Head'
import MainLedgerCard4Footer from './MainLedgerCard4Footer'
import { round, parseNum } from '@/snippets/allSnippets'


export default {
  name: 'mainledgercard4',
  props: ['items', 'head', 'showEUR'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      card: {},
      type: 0,
      cardNr: 0,
      exRate: 1
    }
  },
  components: {
    MainLedgerPrintCard4,
    MainLedgerCardHead,
    MainLedgerCard4Head,
    MainLedgerCard4Footer
  },
  async mounted () {
    console.log('kartica 4')
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.exRate = this.$store.state.defExRate ? this.$store.state.defExRate : 1

    if (!this.showEUR) {
      this.exRate = 1
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    this.type = this.head.type ? this.head.type : 0
    // this.Total1 = 0
    // this.Total2 = 0
    // this.Total3 = 0
    // this.Total4 = 0
    // let itLine = 0
    // if (this.items) {
    //   this.items.map(item => {
    //     itLine += 1
    //     this.Total1 += parseFloat(item.Debit)
    //     this.Total2 += parseFloat(item.Credit)
    //     this.Total3 += parseFloat(item.DebitForegin)
    //     this.Total4 += parseFloat(item.CreditForegin)
    //     const bookName = item.BookName !== 'BS' ? item.BookName : 'Izv.' + parseInt(item.BookID)
    //     const object2 = {
    //       itBookName: itLine + '. ' + bookName,
    //       CreditDebit: this.Total1 - this.Total2
    //     }
    //     const object3 = {...item, ...object2}
    //     this.pItems.push(object3)
    //   })
    // }
    // this.Total5 = this.Total1 - this.Total2
    // this.card = {...this.head}
    // this.card.items = this.pItems
    // //this.card = {...this.card, ...this.lastOne}
    // this.card.Total1 = this.Total1
    // this.card.Total2 = this.Total2
    // this.card.Total5 = this.Total5

  },
  computed: {

  },
  methods: {
    print(){
      this.$refs.prnt.print()
    },
    round2(param) {
      return round(param)
    },
    parseNum2(param) {
      return parseNum(param)
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    close () {
      this.show = false
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
