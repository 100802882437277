<template>
  <v-container fluid class="py-10">
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>
      <v-row v-if="error">
              <v-alert 
                :type="typeError"
                v-model="alert"
                dismissible>
               {{error}}
              </v-alert>
            </v-row>
        <v-card>
          <v-card-title>
            {{ documentNameLoc }}
            <span class="blue--text">, Za rasporediti: {{useAmount}}</span>
            <span class="green--text">,  Rasporedjeno: {{allSelectedAmount}}</span>
            <span class="pink--text">,  Ostalo: {{restAmount}}</span>
          </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="pItems"
            item-key="Line"
            show-select
            class="elevation-1"
          >
            <template v-slot:[`item.itInvoice`]="{ item }">
                <span>{{ parseInt(item.itInvoice) }}</span>
            </template>
            <template v-slot:[`item.invoiceInNumber`]="{ item }">
                <span v-if="item.invoiceInNumber !== 0">{{ parseInt(item.invoiceInNumber) }}</span>
            </template>
            <template v-slot:[`item.invoiceOutNumber`]="{ item }">
                <span v-if="item.invoiceOutNumber !== 0">{{ parseInt(item.invoiceOutNumber) }}</span>
            </template>
            <template v-slot:[`item.Debit`]="{ item }">
                <span>{{ localMoney(item.Debit) }}</span>
            </template>
             <template v-slot:[`item.Credit`]="{ item }">
                <span>{{ localMoney(item.Credit) }}</span>
            </template>
             <template v-slot:[`item.CreditDebit`]="{ item }">
                <span>{{ localMoney(item.CreditDebit) }}</span>
            </template>

            <template v-slot:[`item.BookingDate`]="{ item }">
                <span>{{ localDate(item.BookingDate) }}</span>
            </template>
            <template v-slot:[`item.InvoiceDate`]="{ item }">
                <span>{{ localDate(item.InvoiceDate) }}</span>
            </template>
             <template v-slot:[`item.InvoicePaymentDeadlineDate`]="{ item }">
                <span>{{ localDate(item.InvoicePaymentDeadlineDate) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-row v-if="showSelected">
      <mainledgercard3selected :ssitems="ssitems" :key="locid"/>
    </v-row>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import mainledgercard3selected from './MainLedgerCard3Selected'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
//import {checkPartners} from '../MainLedger/mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'
import {parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercard3selection',
  props: ['items', 'head', 'debit', 'credit'],
  data () {
    return {
      alert: true,
      documentNameLoc: '',
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      typeError: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partner: {},
      partners: [],
      card: {},
      mainQuery: {},
      singleSelect: false,
      selected: [],
      useAmount: 0,
      selectedAmount: 0,
      allSelectedAmount: 0,
      restAmount: 0,
      ssitems: [],
      midItems: [],
      order: 0,
      locid: 1,
      showSelected: false,
      headers: [],
    }
  },
  components: {
    mainledgercard3selected
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
     const ureTable = [
        { text: 'Vrsta', align: 'start', value: 'BookName'},
        { text: 'Broj dok.', value: 'itInvoice' },
        { text: 'VEZA', value: 'invoiceInNumber' },
        { text: 'Orig.račun', value: 'InvoiceNumber' },
        { text: 'Datum knjiženja', value: 'BookingDate' },
        { text: 'Datum dokumenta', value: 'InvoiceDate' },
        { text: 'Datum dospijeća', value: 'InvoicePaymentDeadlineDate' },
        { text: 'Opis', value: 'Description' },
        { text: 'Konto', value: 'account4' },
        { text: 'Duguje', value: 'Debit' },
        { text: 'Potražuje', value: 'Credit' },
        { text: 'Saldo', value: 'CreditDebit' },
    ]
     const ireTable = [
        { text: 'Vrsta', align: 'start', value: 'BookName'},
        { text: 'Broj dok.', value: 'itInvoice' },
        { text: 'VEZA', value: 'invoiceOutNumber' },
        { text: 'Datum knjiženja', value: 'BookingDate' },
        { text: 'Datum dokumenta', value: 'InvoiceDate' },
        { text: 'Datum dospijeća', value: 'InvoicePaymentDeadlineDate' },
        { text: 'Opis', value: 'Description' },
        { text: 'Konto', value: 'account4' },
        { text: 'Duguje', value: 'Debit' },
        { text: 'Potražuje', value: 'Credit' },
        { text: 'Saldo', value: 'CreditDebit' },
    ]
    this.headers = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? [...ureTable] : [...ireTable]
    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    //await checkPartners(0)
    await testLastUpdate()
    // if (this.$store.state.partnerStore) {
    //   this.partners = this.$store.state.partnerStore
    // }
    //this.partners = (await PartnerService.index()).data.partners

    this.useAmount = round(Math.abs(this.debit - this.credit), 2)
    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    let itLine = 0
    let partnerVATID = null
    // console.log('this.items ', this.items)
    //console.log('Card3 Selection this.items', this.items)

    if (this.items) {
      this.items.sort((a,b) => (a.BookingDate > b.BookingDate) ? 1 : ((b.BookingDate > a.BookingDate) ? -1 : 0))
      this.items.map(item => {
        itLine += 1
        this.Total1 += round(parseNum(item.Debit), 2)
        this.Total2 += round(parseNum(item.Credit), 2)
        //this.Total3 += parseFloat(item.DebitForegin)
        //this.Total4 += parseFloat(item.CreditForegin)
        partnerVATID = item.partnerVATID ? item.partnerVATID : null
        const itInvoice = parseInt(item.invoiceInNumber) > 0 ? parseInt(item.invoiceInNumber) : parseInt(item.invoiceOutNumber)
        const bookName = item.BookName !== 'BS' ? item.BookName : 'Izv.' + parseInt(item.BookID)
        const CreditDebit = round(this.Total1 - this.Total2, 2)
        const object2 = {
          Line: itLine,
          BookName: bookName,
          CreditDebit: CreditDebit,
          itInvoice: itInvoice
        }
        const object3 = {...item, ...object2}
        this.pItems.push(object3)
      })
    }
    
    this.Total5 = this.Total1 - this.Total2
    this.partner = partnerVATID ? this.$store.state.partnerStore.find(partner => (partner.partnerVATID === partnerVATID)) : {}
    this.documentNameLoc = 'Otvorene stavke: ' + this.partner.partnerName +  ', OIB:'+ this.partner.partnerVATID
  },
  computed: {

  },
  methods: {
    print(){
      this.$refs.prnt.print()
    },
    localMoney(moneyTo) {
      return moneyFormat(moneyTo)
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    close () {
      this.show = false
    },
    doCalculation(){
        let saveItems = []
        let saveIt = true
        let runDebit =  this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? true : false
        let allSelected = 0
        
        if (this.midItems.length === 0){
          this.allSelectedAmount = 0
          saveIt = false
          this.showSelected = false
        }
        
        if (this.allSelectedAmount <= this.useAmount) {
          if (this.midItems && this.midItems.length > 0) {
            this.allSelectedAmount = 0
            let allDebit = 0
            let allCredit = 0
            // do math
            this.midItems.map(item => {
              // const itemAmount = round(Math.abs(parseNum(item.Debit) - parseNum(item.Credit)), 2)
              
              const dDebit = round(parseNum(item.Debit),2)
              const cCredit = round(parseNum(item.Credit),2)
              const itemAmount = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? round((cCredit - dDebit),2) : round((dDebit - cCredit), 2) 
              allDebit += round(dDebit,2)
              allCredit += round(cCredit,2)
              allDebit = round(allDebit, 2)
              allCredit = round(allCredit,2)

              //let itemAmount = 0
              // if (runDebit) {
              //   if (dDebit >= 0 && cCredit >=0) {
              //     itemAmount = round((cCredit - dDebit), 2)
              //   }
              //   if (dDebit >= 0 && cCredit < 0) {
              //     itemAmount = round((dDebit + cCredit), 2)
              //   }
              // }
              // if (!runDebit) {
              //   if (dDebit >= 0 && cCredit >=0) {
              //     itemAmount = round((dDebit - cCredit), 2)
              //   }
              //   if (dDebit < 0 && cCredit >= 0) {
              //     itemAmount = round((dDebit + cCredit), 2)
              //   }
              // }

    
              // allSelected += round(itemAmount, 2)
              // allSelected = round(allSelected, 2)

              if (allDebit === 0 && allCredit !== 0) {
                allSelected = allCredit
              }
              if (allDebit !== 0 && allCredit === 0) {
                allSelected = allDebit
              }
              // if buyer or supplier
              if (allDebit !== 0 && allCredit !== 0) {
                allSelected = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? round((allCredit - allDebit),2) : round((allDebit - allCredit), 2) 
              }
              allSelected = round(allSelected, 2)
              let goToLast = true
              
              if (allSelected <= this.useAmount){
                goToLast = false
                let newItem = {...item}
                newItem.Debit = cCredit
                newItem.Credit = dDebit

                // if (runDebit) {
                //   newItem.Debit = round(itemAmount, 2)
                //   newItem.Credit = 0
                // }
                
                // if (!runDebit) {
                //   newItem.Debit = 0
                //   newItem.Credit = round(itemAmount, 2)
                // }
                //this.allSelectedAmount += round(itemAmount, 2)
                this.allSelectedAmount = round(allSelected, 2)
                saveItems.push(newItem)
              }

              if (goToLast){
                if (round(allSelected - itemAmount, 2) <= this.useAmount) {
                  const haveRest = round(this.useAmount, 2) - round((allSelected - itemAmount), 2)
                  let newItem = {...item}
                  
                  if (runDebit) {
                    newItem.Debit = round(haveRest, 2)
                    newItem.Credit = 0
                  }
                  if (!runDebit) {
                    newItem.Debit = 0
                    newItem.Credit = round(haveRest, 2)
                  }
                  this.allSelectedAmount += round(haveRest, 2)
                  saveItems.push(newItem)
                }
              }

            })
          }
        
          if (saveIt) {
            // this.allSelectedAmount = 0
            // if (saveItems && saveItems.length > 0) {
            //   saveItems.map(detail => {
            //     this.allSelectedAmount += round(Math.abs(detail.Debit - detail.Credit), 2)
            //   })
            // }
            this.allSelectedAmount = round(this.allSelectedAmount, 2)
            this.ssitems = [...saveItems]
            
            this.locid += 1 
            this.showSelected = true
            this.restAmount = round(this.useAmount - this.allSelectedAmount, 2)
            this.$store.dispatch('setMainLedgerDetailItems', saveItems)
          }
        } 
    },
  },
  watch: {
    'selected' ()
      {
        // copy selected to midItems, by clicked order 
        if (this.selected && this.selected.length > 0) {
          
          this.selected.map(item => {
            let addNewOne = true
            if (this.midItems && this.midItems.length > 0) {
              const filteredItem = this.midItems.filter(mItem => mItem.Line === item.Line)
              if (filteredItem && filteredItem.length > 0) {
                addNewOne = false
              }              
            }
            if (addNewOne) {
              const newItem = {...item}
              this.order +=1
              newItem.order = this.order 
              this.midItems.push(newItem)
            }
          })
        }
        if (this.selected && this.selected.length === 0) {
          const newArr = []
          this.midItems = [...newArr]
        }
        // remove from midItems, that are not in selected
        if (this.midItems && this.midItems.length >0) {
          this.midItems.map(mItem => {
            const index = this.midItems.indexOf(mItem)
            const filMidItem = this.selected.filter(sItem =>sItem.Line === mItem.Line)
            if (filMidItem && filMidItem.length === 0) {
              this.midItems.splice(index,1)
            }
          })
        }
        this.doCalculation()
    }
 }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
